<template>
  <div class="fragment noPrintable">

<!--    <SearchEngine/>-->

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'generateReport_completed',
          'generateReport_missing',
          'generateReport_notPaid',
          'generateReport_engraving',
        ])"></div>

      <div class="content-tabs content-tabs--separator"
           v-if="Object.keys($store.getters.getUserProfile).length > 0"
           v-bind:class="{'loading-tabs': $store.getters.getGenerateReportAMLoading === true || $store.getters.getGenerateReportLoading === true
           || $store.getters.getGenerateReportMDLoading === true}"
      >
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              <template>
                {{item.label}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>
    <div class="light-bg-block" v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'generateReport_all',
          'generateReport_mine',
          'generateReport_rest',
          'generateReport_currentOrders',
        ])"></div>

      <div v-if="navTabs && navTabs.completed && navTabs.completed.active" class="btn-left-block">
<!--        <div class="btn-left-block-i">-->
<!--          <DefaultCheckbox-->
<!--              :label="$t('common_selectAll.localization_value.value')"-->
<!--              @input="(val) => $emit('selectAll', val)"-->
<!--          />-->
<!--        </div>-->
        <template v-for="(item, index) in navTabs.completed.subTabs">
          <div :key="index" class="btn-left-block-i">
            <MainButton
                :value="item.label"
                v-bind:class="{brown: item.active}"
                @click.native="$emit('changeTabSub', item.name, false, 'completed')"
            ></MainButton>
          </div>
        </template>

        <div class="btn-left-block-i">
          <router-link :to="$store.getters.GET_PATHS.reportsGenerateReportCurOrdersPage">
            <MainButton
                class="btn-fit-content"
                :value="$t('generateReport_currentOrders.localization_value.value')"
            ></MainButton>
          </router-link>
        </div>
      </div>

      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'generateReport_addToMine',
          'generateReport_pick',
        ])"></div>
        <MainButton
            v-if="navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
              && ((navTabs.completed.subTabs.rest && navTabs.completed.subTabs.rest.active) || (navTabs.completed.subTabs.all && navTabs.completed.subTabs.all.active))"
            :value="$t('generateReport_addToMine.localization_value.value')"
            class="mr-3"
            @click.native="$emit('addToMine')"
        >
        </MainButton>
        <MainButton
            v-if="navTabs && navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
              && navTabs.completed.subTabs.mine && navTabs.completed.subTabs.mine.active"
            :value="$t('generateReport_pick.localization_value.value')"
            class="mr-3"
            @click.native="$emit('pickOrders')"
        >
        </MainButton>
        <span @click="printTable">
         <MainButton
             :value="$t('generateReport_print.localization_value.value')"
             class="print-btn-head"
             :ico="true"
         >
          <template slot="ico">
            <PrintWhiteIcon/>
          </template>
        </MainButton>
        </span>
      </div>
    </div>



  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import PrintWhiteIcon from "../../../../../../../../public/img/common/print-white-icon.svg?inline";


  export default {
    name: "GenerateReportPageHead",
    components: {
      ToggleFilterButton,
      MainButton,
      // SearchEngine,
      PrintWhiteIcon,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
        printFile: null,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      openHTMLCodePopup(){
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      },
      printTable() {

        window.print()

        // try {
        //   const { fileContentBase64 } = await this.$store.dispatch("incrementReportPrint", '?type=complete&start_time_interval=12:00:00&end_time_interval=23:59:59');
        //   const winParams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,'+
        //       'resizable,screenX=50,screenY=50,width=850,height=1050';
        //
        //   const htmlPop = '<embed width=100% height=100%'
        //       + ' type="application/pdf"'
        //       + ' src="data:application/pdf;base64,'
        //       + escape(fileContentBase64)
        //       + '"></embed>';
        //
        //   const printWindow = window.open ("", "PDF", winParams);
        //   printWindow.document.write (htmlPop);
        //   printWindow.print();
        // } catch (err) {
        //   console.log(err);
        // }
      },
    },
  }
</script>

<style scoped lang="scss">
@media (max-width: 992px) {
  .main-button:not(.secondary):hover {
    background: #8f7a61 !important;
  }
}
@media (max-width: 680px) {
  .btn-left-block-i {
    margin-left: 10px;
  }

  .btn-left-block {
    .main-button {
      height: 35px;
      font-size: 14px;
      padding: 0 8px;
    }
  }
}
@media (max-width: 420px) {
  .btn-left-block-i {
    margin-left: 0;
  }

  .btn-left-block {
    flex-direction: column;
    .main-button {
      margin-left: 0;
      margin-bottom: 6px;
    }
  }
}
</style>

<style media="print">

  .print-btn-head{
    max-width: 167px;
  }

  @page { size: portrait; }

  @media print {
    * {
      -webkit-print-color-adjust: exact;
    }
    body .noPrintable {
      display: none;
    }

    .default-layout__page-wrap{
      padding-left: 0 !important;
    }
    .manager-btn{
      display: none !important;
    }

    .tooltip-helper.max-width-helper{
      width: 250px !important;
    }
    .tooltip-helper-full{
      white-space: initial !important;
      max-width: 200px !important;
    }

    .header__inner{
      display: none !important;
    }

    .default-layout__page-content{
      margin-top: 30px;
    }

    .site-table-wrap:not(.report-table-all) td:nth-child(6) span,
    .site-table-wrap.report-table-all td:nth-child(7) span {
      display: block;
      max-width: 100px;
      white-space: initial !important;
    }

    .site-table thead th {
      font-size: 9px;
    }

    .site-table tbody td {
      font-size: 9px;
    }

    .site-table .table-link {
      font-size: 9px;
    }

    .site-table-wrap.report-table-all th:nth-child(3),
    .site-table-wrap.report-table-all td:nth-child(3) {
      display: none;
    }
    .site-table-wrap:not(.report-table-all) th:nth-child(2),
    .site-table-wrap:not(.report-table-all) td:nth-child(2) {
      display: none;
    }

    .site-table-wrap.report-table-mine th:nth-child(2),
    .site-table-wrap.report-table-mine td:nth-child(2),
    .site-table-wrap.report-table-rest th:nth-child(2),
    .site-table-wrap.report-table-rest td:nth-child(2) {
      display: initial;
    }

    :not(.report-table-all) .site-table th:nth-child(4),
    .report-table-all .site-table th:nth-child(5) {
      width: 20%;
    }

    :not(.report-table-all) .site-table td:nth-child(4),
    .report-table-all .site-table td:nth-child(5) {
      width: 20%;
    }

    .report-table-mine .site-table th:nth-child(1),
    .report-table-rest .site-table th:nth-child(1),
    .report-table-all .site-table th:nth-child(1),
    .report-table-mine .site-table td:nth-child(1),
    .report-table-rest .site-table td:nth-child(1),
    .report-table-all .site-table td:nth-child(1) {
      display: none;
    }
  }
</style>

