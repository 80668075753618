<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>

        <SearchSelect
            :options="options"
            :label="$t('common_user.localization_value.value')"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :userEmail="true"
            :typeSelect="'users'"
        />
      </div>

      <div class="table-filter__item">
        <DatePickerDefault
            :label="$t('generateReport_Period.localization_value.value')"
            v-model="createdAt"
        >
          <template slot="body">
            <date-picker
                v-model="createdAt"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100 mb-3"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['generateReport_notShowUsers'])"></span>

        <MultiSearchSelect
            :options="options"
            :label="$t('generateReport_notShowUsers.localization_value.value')"
            :functionSearch="functionSearch"
            @change="changeUsersMultiSearchFilter"
            :selected="selectedUsers"
            :userEmail="true"
            :typeSelect="'users'"
        />
      </div>

      <div class="table-filter__item">
        <DefaultSelect
            class="w-100"
            :optionsLabel="'name'"
            :options="optionsStorage"
            :label="$t('productsReport_Storage.localization_value.value')"
            :selected="_.find(optionsStorage, {id: parseInt(storage)})"
            @change="changeStorageFilter"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "@/mixins/usersMixins/usersFunc";
  import MultiSearchSelect from "../../../../../../UI/selectiones/MultiSearchSelect/MultiSearchSelect";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "GenerateReportPageFilter",
    components: {
      DefaultSelect,
      MultiSearchSelect,
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userIdsNotIncl: this.filterGetParams.userIdsNotIncl ? this.filterGetParams.userIdsNotIncl : '',
        // userNamesNotIncl: this.filterGetParams.userNamesNotIncl ? this.filterGetParams.userNamesNotIncl : '',
        createdAt: this.filterGetParams.createdAt ? this.generateFilterDate(this.filterGetParams.createdAt, 'MM/DD/YY'): '',

        storage: this.filterGetParams.storage ? this.filterGetParams.storage : '',
        
        options: [],
        selectedUsers: [],

        filterCounts: [
          'userId',
          'userName',
          'userIdsNotIncl',
          // 'userNamesNotIncl',
          'createdAt',
          'storage',
        ],

        optionsStorage: [],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userIdsNotIncl = newVal.userIdsNotIncl ? newVal.userIdsNotIncl : ''
        // this.userNamesNotIncl = newVal.userNamesNotIncl ? newVal.userNamesNotIncl : ''
        this.createdAt = newVal.createdAt ? this.generateFilterDate(newVal.createdAt, 'MM/DD/YY') : ''

        this.storage = newVal.storage ? newVal.storage : ''
      },

    },

    mounted() {
      if (this.$route.query?.userIdsNotIncl) {

        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')

        myQuery.where('UserId', this.$route.query?.userIdsNotIncl)

        url = url + myQuery.limit(1000).page(0).url().split('?')[1]

        this.$store.dispatch('fetchUsers', url).then(result => {
          this.selectedUsers = this.getRespPaginateData(result)
        })
      }

      this.getWarehouseStorages()
    },

    methods: {

      async getWarehouseStorages() {
        return this.$store.dispatch('fetchWarehouseStorage').then((response) => {
          this.optionsStorage = this.getRespPaginateData(response)
        })
      },

      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)
        this.selectedUsers = []

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.createdAt = this.changeDateParams(this, 'createdAt')

        this.$emit('changeFilter', data)
      },

      changeStorageFilter(val) {
        this.storage = val.id
      },
    },
  }
</script>

<style scoped>
  .filter-wrapper-scoped{
    max-width: 915px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
  }
</style>
