import { render, staticRenderFns } from "./GenerateReportPageTableAdmin.vue?vue&type=template&id=232b339c&scoped=true"
import script from "./GenerateReportPageTableAdmin.vue?vue&type=script&lang=js"
export * from "./GenerateReportPageTableAdmin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232b339c",
  null
  
)

export default component.exports