<template>
  <GenerateReportPageTableAdmin
      v-if="isAdmin"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      :navTabs="navTabs"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @changeTab="changeTab"
      @changeTabSub="changeTabSub"
      @reload="reload"
      @addToMine="addToMine"
      @pickOrders="pickOrders"
      @selectAll="selectAll"
  />
</template>

<script>
  import GenerateReportPageTableAdmin from "./GenerateReportPageTableAdmin/GenerateReportPageTableAdmin";
  import { routeFilter } from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";

  export default {
    name: "GenerateReportPageTable",
    components: {
      GenerateReportPageTableAdmin,
    },

    mixins: [routeFilter, mixinDetictingMobile],

    data() {
      return {
        forPage: this.$store.getters.getGenerateReportForPage,
        page: 1,
        onePage: 1,
        filterType: '',
        filterSubType: '',

        filterUserId: '',
        filterUserName: '',
        filterUserIdsNotIncl: '',
        filterUserNamesNotIncl: '',
        filterCreatedAt: [],
        filterExcludeUser: '',
        filterStorage: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          completed: {
            active: true,
            label: this.$t('generateReport_completed.localization_value.value'),
            name: 'completed',
            id: false,
            //subcategories
            subTabs: {
              all: {
                active: true,
                label: this.$t('generateReport_all.localization_value.value'),
                name: 'all',
                id: false,
              },
              mine: {
                active: false,
                label: this.$t('generateReport_mine.localization_value.value'),
                name: 'mine',
                id: false,
              },
              rest: {
                active: false,
                label: this.$t('generateReport_rest.localization_value.value'),
                name: 'rest',
                id: false,
              },
            },

          },
          missing: {
            active: false,
            label: this.$t('generateReport_missing.localization_value.value'),
            name: 'missing',
            id: false,
          },
          notPaid: {
            active: false,
            label: this.$t('generateReport_notPaid.localization_value.value'),
            name: 'notPaid',
            id: false,
          },
          engraving: {
            active: false,
            label: this.$t('generateReport_engraving.localization_value.value'),
            name: 'engraving',
            id: false,
          },
        },
      }
    },
    mounted() {
      if(this.$route.query.storage === undefined){
        let query = Object.assign({}, this.$route.query);
        query.storage = 1
        this.$router.replace({ query });
      }
      if(this.$route.query.subType) {
        this.skipRadioChecked(this.navTabs.completed.subTabs, 'active')
        this.navTabs.completed.subTabs[this.$route.query.subType].active = true
      }

      //this.$store.dispatch('getOrdersFBMPickedForBin')

      this.filter();
    },
    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          userIdsNotIncl: this.$route.query.userIdsNotIncl,
          userNamesNotIncl: this.$route.query.userNamesNotIncl,
          createdAt: this.$route.query.createdAt,
          excludeUser: this.$route.query.excludeUser,

          storage: this.$route.query.storage,
          subType: this.$route.query.subType,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        // let rData = {
        //   fbm_ids: [372]
        // }
        // this.$store.dispatch('updateAdminIdForFBM', rData).then(response => {
        //   console.log(response);
        //   console.log(5555555);
        // })

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this);

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL

        // let urlTimeAM = this.generateFilterUrl(
        //     generateMainFilter.page,
        //     generateMainFilter.forPage,
        //     'startTime'
        // )
        //
        // let urlTimeMD = this.generateFilterUrl(
        //     generateMainFilter.page,
        //     generateMainFilter.forPage,
        //     'middleTime'
        // )
        //
        // let urlTimePM = this.generateFilterUrl(
        //     generateMainFilter.page,
        //     generateMainFilter.forPage,
        //     'endTime'
        // )

        let url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next)
          this.$store.commit('setNextGenerateReportPage', true)

        let urlBefore = url + '&block=before12'
        let urlBetween = url + '&block=between12and14'
        let urlAfter = url + '&block=after14'

        // if (this.isMobileFunc()) {
        //   urlBefore = '-mobile' + urlBefore
        // }

        this.$store.dispatch('fetchGenerateReportBeforeHTML', urlBefore).then(() => {
          this.$store.commit('setGlobalUpdateTable', false)
        })

        this.$store.dispatch('fetchGenerateReportBetween', urlBetween).then(() => {
          this.$store.commit('setGlobalUpdateTable', false)
        })

        this.$store.dispatch('fetchGenerateReportAfter', urlAfter).then(() => {
          this.$store.commit('setGlobalUpdateTable', false)
          console.log(342342);
          console.log(this.$store.getters.getGenerateReport);
        })

        // this.$store.dispatch('fetchGenerateReport', url).then(() => {
        //   this.$store.commit('setGlobalUpdateTable', false)
        // })
        // // after 12:00 before 14:00
        // this.$store.dispatch('fetchGenerateReportMD', urlTimeMD).then(() => {
        //   this.$store.commit('setGlobalUpdateTable', false)
        // });
        // // after 14:00
        // this.$store.dispatch('fetchGenerateReportAM', urlTimeAM).then(() => {
        //   this.$store.commit('setGlobalUpdateTable', false)
        // });


        this.checkCountFilter(['userName', 'subType'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()
        let queryParams = {
          filterUserId: 'userId',
          filterUserIdsNotIncl: 'exclude_user_id',
          filterStorage: 'byStorageId',
          // excludeUser: 'ExcludeUser',
        }

        if (this.navTabs.completed.active && this.$route.query.subType && this.$route.query.subType !== 'all') {
          queryParams['filterSubType'] = 'by_owner_admin'
        }

        this.generateFilterQueryParams(
            myQuery,queryParams,
        )

        if (this.filterCreatedAt.length > 0) {
          let date = this.generateDateForFilter(this.filterCreatedAt, 'YYYY-MM-DD')
          myQuery.whereIn('between_created_at', date)
        }

        let url = this.generateGetParamsFromQuery(myQuery, forPage, page)
        // let timeFilter = time === 'startTime' ? '&start_time_interval=00:00:00&end_time_interval=12:00:00' :
        //     time === 'middleTime' ?
        //     '&start_time_interval=12:00:00&end_time_interval=14:00:00' :
        //     '&start_time_interval=14:00:00&end_time_interval=23:59:59'
        // url += timeFilter

        if(this.navTabs.completed.active){
          url += '&type=complete'
        }

        if(this.navTabs.missing.active){
          url += '&type=missing'
        }

        if(this.navTabs.notPaid.active){
          url += '&type=notPaid'
        }

        if(this.navTabs.engraving.active){
          url += '&filter[engraving_orders]=1&type=complete'
        }

        return url
      },

      addToMine() {
        let addedIds = this.getCheckedRows('order-id')

        if (addedIds.length === 0) return

        let data = {
          'fbm_ids': addedIds
        }

        this.$store.dispatch('updateAdminIdForFBM', data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.reload()
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        }).catch(error => this.createErrorLog(error))
      },

      pickOrders() {
        let addedIds = this.getCheckedRows('order-id')

        if (addedIds.length === 0) return

        let data = {
          'ids': addedIds
        }

        this.$store.dispatch('prepareReportOrdersFBM', data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.$router.push(this.$store.getters.GET_PATHS.reportsGenerateReportCurOrdersPage)
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        }).catch(error => this.createErrorLog(error))
      },

      selectAll(val) {
        let elements = document.querySelectorAll('[data-order-id]')
        elements.forEach(item => {
          item.checked = val
        })
      }
    }
  }
</script>

<style scoped>

</style>
