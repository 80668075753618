var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment noPrintable"},[_c('div',{staticClass:"content-top-line content-top-line--with-bottom-line",class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'generateReport_completed',
          'generateReport_missing',
          'generateReport_notPaid',
          'generateReport_engraving',
        ])}}}),(Object.keys(_vm.$store.getters.getUserProfile).length > 0)?_c('div',{staticClass:"content-tabs content-tabs--separator",class:{'loading-tabs': _vm.$store.getters.getGenerateReportAMLoading === true || _vm.$store.getters.getGenerateReportLoading === true
           || _vm.$store.getters.getGenerateReportMDLoading === true}},[_vm._l((_vm.navTabs),function(item,index){return [_c('div',{key:index,staticClass:"content-tabs__item",class:{active: item.active},on:{"click":function($event){return _vm.$emit('changeTab', item.name)}}},[_c('div',{staticClass:"content-tabs__ico"},[[_vm._v(" "+_vm._s(item.label)+" ")]],2)])]})],2):_vm._e(),_c('div',{staticClass:"content-top-line-wrap"},[_c('ToggleFilterButton',{attrs:{"count":_vm.countFilterParams,"showFilter":_vm.showFilter},on:{"toggleFilter":function($event){return _vm.$emit('toggleFilter')}}})],1)]),_c('div',{staticClass:"light-bg-block",class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'generateReport_all',
          'generateReport_mine',
          'generateReport_rest',
          'generateReport_currentOrders',
        ])}}}),(_vm.navTabs && _vm.navTabs.completed && _vm.navTabs.completed.active)?_c('div',{staticClass:"btn-left-block"},[_vm._l((_vm.navTabs.completed.subTabs),function(item,index){return [_c('div',{key:index,staticClass:"btn-left-block-i"},[_c('MainButton',{class:{brown: item.active},attrs:{"value":item.label},nativeOn:{"click":function($event){return _vm.$emit('changeTabSub', item.name, false, 'completed')}}})],1)]}),_c('div',{staticClass:"btn-left-block-i"},[_c('router-link',{attrs:{"to":_vm.$store.getters.GET_PATHS.reportsGenerateReportCurOrdersPage}},[_c('MainButton',{staticClass:"btn-fit-content",attrs:{"value":_vm.$t('generateReport_currentOrders.localization_value.value')}})],1)],1)],2):_vm._e(),_c('div',{staticClass:"btn-right-block",class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'generateReport_addToMine',
          'generateReport_pick',
        ])}}}),(_vm.navTabs && _vm.navTabs.completed && _vm.navTabs.completed.active && _vm.navTabs.completed.subTabs
              && ((_vm.navTabs.completed.subTabs.rest && _vm.navTabs.completed.subTabs.rest.active) || (_vm.navTabs.completed.subTabs.all && _vm.navTabs.completed.subTabs.all.active)))?_c('MainButton',{staticClass:"mr-3",attrs:{"value":_vm.$t('generateReport_addToMine.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('addToMine')}}}):_vm._e(),(_vm.navTabs && _vm.navTabs.completed && _vm.navTabs.completed.active && _vm.navTabs.completed.subTabs
              && _vm.navTabs.completed.subTabs.mine && _vm.navTabs.completed.subTabs.mine.active)?_c('MainButton',{staticClass:"mr-3",attrs:{"value":_vm.$t('generateReport_pick.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('pickOrders')}}}):_vm._e(),_c('span',{on:{"click":_vm.printTable}},[_c('MainButton',{staticClass:"print-btn-head",attrs:{"value":_vm.$t('generateReport_print.localization_value.value'),"ico":true}},[_c('template',{slot:"ico"},[_c('PrintWhiteIcon')],1)],2)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }