<template>
  <div>
    <GenerateReportPageHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @changeTabSub="(dataTab, dataTabs, dataParent) => $emit('changeTabSub', dataTab, dataTabs, dataParent)"
        @reload="$emit('reload')"
        @addToMine="$emit('addToMine')"
        @pickOrders="$emit('pickOrders')"
    />

    <div class="table-filter-wrap">

      <GenerateReportPageFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <GenerateReportPageTable
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @selectAll="(val) => $emit('selectAll', val)"
      />
    </div>
  </div>
</template>

<script>


  import GenerateReportPageHead from "./GenerateReportPageHead/GenerateReportPageHead";
  import GenerateReportPageFilter from "./GenerateReportPageFilter/GenerateReportPageFilter";
  import GenerateReportPageTable from "./GenerateReportPageTable/GenerateReportPageTable";

  export default {
    name: "GenerateReportPageTableAdmin",

    components: {
      GenerateReportPageHead,
      GenerateReportPageFilter,
      GenerateReportPageTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
        activeTab: 1,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      changeTab(id) {
        this.activeTab = id
      },
    }
  }
</script>

<style scoped>

</style>
