<template>
  <div class="table-card__item-content">
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <div class="table-card__item-number table-card__item-number--small">
          <router-link class="table-link"
                       target="_blank"
                       :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getFBMType(report.order.type) + '/' + report.order.id">
            {{ report.order.id }}
          </router-link>
        </div>
        <div v-if="navTabs && navTabs.completed && navTabs.completed.active"
             style="margin-left: auto;"
        >
          <DefaultCheckbox
              v-if="(index === 0 || (index > 0 && $store.getters.getGenerateReport[index - 1] && $store.getters.getGenerateReport[index - 1].order
                  && $store.getters.getGenerateReport[index - 1].order.id && report.order && report.order.id && report.order.id !== $store.getters.getGenerateReport[index - 1].order.id))
                  && !(report.order_bins && report.order_bins.length > 0)"
              class="empty-label"
              :dataOrderId="report.order.id"
          />
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('generateReport_User.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div class="table-row d-flex flex-wrap">
                <!--                <StatusIcoBtn-->
                <!--                    class="mr-1 t-0"-->
                <!--                    :type="'delivered'"-->
                <!--                />-->
                <!--                <LabelCircle-->
                <!--                    class="oval mr-1"-->
                <!--                    :color="'blue'"-->
                <!--                    :value="'NEW'"-->
                <!--                />-->
                <div class="table-link btn-style mr-1 white-space-line">
<!--                  <TableUserColumn-->
<!--                      :item="{user: {-->
<!--                              id: report.user.id,-->
<!--                              show_n_mark: report.user.show_n_mark,-->
<!--                              user_settings: report.user.user_settings,-->
<!--                              user_personal_contact: {user_full_name: report.user.user_full_name},-->
<!--                            }}"-->
<!--                      :mobile="true"/>-->
                  <TableUserColumn
                      :item="report"
                      :mobile="true"/>
                </div>
              </div>
            </div>
          </div>

          <div class="table-card__item-col custom-col"
               v-if="navTabs && ((navTabs.completed && navTabs.completed.active && navTabs.completed.subTabs
              && navTabs.completed.subTabs.all && navTabs.completed.subTabs.all.active) || (navTabs.missing && navTabs.missing.active)
              || (navTabs.notPaid && navTabs.notPaid.active))"
          >
            <div class="table-card__item-label">
              {{ $t('generateReport_Admin.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div class="table-row d-flex flex-wrap">
                <div class="table-link btn-style mr-1 white-space-line"
                     v-if="report.order_admin && !(Array.isArray(report.order_admin) && report.order_admin.length === 0)"
                     :data-user-id="report.order_admin.id"
                     :data-user-full-name="report.order_admin.user_personal_contact.user_full_name"
                     :data-user-email="report.order_admin.email"
                >
                  {{report.order_admin.user_personal_contact.user_full_name}}
                </div>
              </div>
            </div>
          </div>

          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('generateReport_Name.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ report.product.combo_name }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('generateReport_Inbound.localization_value.value') }}
            </div>
            <div class="table-card__item-info direction-column">
              <router-link class="table-link"
                           target="_blank"
                           v-if="report.product.last_inbound && _.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id"
                           :to="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
                                getConsolidationTypeByDestinationId(report.product.last_inbound.consolidation_destination_id) + '/' + report.product.last_inbound.order_model_id">
                <span v-if="report.product.last_inbound.order_model_id">{{report.product.last_inbound.order_model_id}}</span>
              </router-link>
              <router-link class="table-link"
                           target="_blank"
                           v-else-if="report.product.last_inbound && _.has(report.product.last_inbound, 'inbound_id') && report.product.last_inbound.inbound_id"
                           :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id">
                <ValueHelper
                    :value="report"
                    :deep="'product.last_inbound.order_model_id'"
                />
              </router-link>
              <br>
              <template v-if="report.product.last_inbound">
                {{ report.product.last_inbound.created_at | moment("DD MMM, YYYY") }}
                <br>
                {{ report.product.last_inbound.created_at | moment("HH:mm:ss") }}
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('generateReport_ShippingMethod.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="report.order.delivery_method && report.order.delivery_method.name === 'DPD.cz'">
                DPD
              </template>
              <ValueHelper
                  v-else
                  :value="report"
                  :deep="'order.delivery_method.name'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('generateReport_Quality.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ report.product.order_item_quantity }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('generateReport_Cell.localization_value.value') }}
            </div>
            <div class="table-card__item-info flex-wrap">
              <template v-for="comboName of report.product.items_in_storage">
                <div v-if="comboName.cell !== null && comboName.cell.combo_name !== null" :key="comboName.id">
                  {{ comboName.cell.combo_name }} ({{comboName.quantity}})
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="table-card__item-btn pl-2">
          <div class="table-card__item-btn-i">
            <LinkButton
                class="mr-3"
                v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"
                :link="$store.getters.GET_PATHS.ordersFBM + '/' + getFBMType(report.order.type) + '/' + report.order.id"
                :value="$t('generateReport_edit.localization_value.value')"
                :type="'edit'"
                :target="'_blank'"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{ $t('generateReport_showMore.localization_value.value') }}
      </div>
    </div>
  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import {FBM_ORDER_TYPES} from "@/staticData/staticVariables";
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import {ordersInboundMixin} from "@/mixins/ordersInbound/ordersInboundMixin";
import {consolidationMixin} from "../../../../../../../../mixins/consolidationMixins/consolidationMixin";
import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {
  name: "GenerateReportPageTableMobile",
  components: {DefaultCheckbox, TableUserColumn, LinkButton, ValueHelper},

  mixins: [ordersInboundMixin, consolidationMixin],

  props: {
    report: Object,
    navTabs: Object,
    index: Number,
  },

  data() {
    return {
      show: false,
    }
  },

  methods: {
    getFBMType(type) {
      let actualType = ''

      switch (type) {
        case 'consolidation':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name
          break
        case 'sklad_create':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name
          break
        case 'user_create':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_LABEL.name
          break
      }

      return actualType
    },

  }

}
</script>

<style scoped>

</style>